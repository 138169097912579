.BtnMenu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  letter-spacing: 5px;
  font-size: 12px;
  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: width .6s cubic-bezier(.25,1,.5,1), height .6s cubic-bezier(.25,1,.5,1), left .6s cubic-bezier(.25,1,.5,1);
  }
  &:hover {
    cursor: pointer;
  }
  &:hover:before {
    width: 6px;
    height: 6px;
    left: 3px;
  }

}