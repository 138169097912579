@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.lightMode {
  &.SomaticYoga {
    background-color: $bg-gradient-light-1;
    color: $font-color-layout-light;

    button {
      border: 1px solid $font-color-layout-light;
    }
  }
}

.darkMode {
  &.SomaticYoga {
    background-color: $bg-gradient-dark-1;
    color: $font-color-layout-dark;

    button {
      border: 1px solid $font-color-layout-dark;
    }
  }
}
.SomaticYoga {
  width: 100vw;
  height: 100%;
  @media screen and (min-width: $screen-size-desktop) {
    @include flex-cover;
  }

  .SomaticYoga--wrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 110px;
    padding-bottom: 100px;
    @media screen and (min-width: $screen-size-desktop) {
      flex-direction: row;
      padding: 50px 100px;
    }
  }

  .SomaticYoga--wrapper-description {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h1 {
      font-size: $font-header-big;
      font-family: 'Jost', sans-serif;
      margin-bottom: 50px;
      @media screen and (min-width: $screen-size-desktop) {
        flex-direction: row;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        font-size: $font-header-big;
      }
    }
    p {
      line-height: $font-line-height-standard;
      margin-bottom: 15px;
      font-size: $font-body-small-mobile;
      @media screen and (min-width: $screen-size-desktop) {
        font-size: $font-body-workshop;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        font-size: $font-body-standard;
      }
    }
    
    .quote {
      font-size: $font-quote;
      margin-bottom: $padding-desktop-s;
      line-height: $font-line-height-standard;
      font-style: italic;
      font-weight: 300;
      @media screen and (min-width: $screen-size-desktop) {
        margin-bottom: $padding-mobile;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        margin-bottom: $padding-desktop-s;
      }
      span {
        display: block;
        font-size: $font-body-standard;
        opacity: 0.6;
        margin-top: 12px;
      }
    }

    > div:nth-of-type(1) {
      position: relative;
      width: 100%;
      margin-bottom: 50px;
      display: block;

      @media screen and (min-width: $screen-size-desktop) {
        display: none;
      }

      img {
        width: 100%;
        
        filter: saturate(0.5);
       
      }

    }

  

    button {
      display: inline-block;
      width: fit-content;
      border-radius: 60px;
      padding: 12px;
    }
  }

  .SomaticYoga--wrapper-image {
    width: 40vw;
    height: 60vh;
    position: relative;
    display: none;
    @media screen and (min-width: $screen-size-desktop) {
      display: block;
    }
    .SomaticYoga--image {
      @include background-cover;
      width: 40vw;
      height: 60vh;


      // background-image: url("https://res.cloudinary.com/dtvsqvonc/image/upload/v1703000686/Mytam/Somatic/somatic_mimi.jpg");
      filter: saturate(0.5);
    }
  
  }

}