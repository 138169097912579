@import '../scss/_variables.scss';

// Color setting for dark/light Mode
.lightMode {
  .Navigation--bg.left {
    background: linear-gradient($bg-gradient-light-1, $bg-gradient-light-2);
  }
  .Navigation--bg.right {
    background: linear-gradient($bg-gradient-light-1, $bg-gradient-light-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: linear-gradient($bg-gradient-light-2, $bg-gradient-light-3);
    }
  }
  .MainMenu--flexbox {
    color: $font-color-layout-light;
  }
}

.darkMode {
  .Navigation--bg.left {
    background: linear-gradient($bg-gradient-dark-1, $bg-gradient-dark-2);
  }
  .Navigation--bg.right {
    background: linear-gradient($bg-gradient-dark-1, $bg-gradient-dark-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: linear-gradient($bg-gradient-dark-2 60%, $bg-gradient-dark-3);
    }
  }
  .MainMenu--flexbox {
    color: $font-color-layout-dark;
  }
}

.MainMenu {
  display: none;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  z-index: 500;
  .Navigation--wrapper {
    opacity: 0;
    > div {
      z-index: 500;
    }
  }
  .MainMenu--flexbox {
    display: flex;
    width: 100%;
    height: 100%;
    .Navigation--bg {
      position: fixed;
      height: 100vh;
      // height: calc(var(--vh, 1vh) * 100);
      top: 0;
      width: 50%;
      overflow: hidden;
      @media screen and (min-width: $screen-size-desktop) {
        height: 100vh;
      }
    }
    .Navigation--bg.left {
      left: 0;
      transform: translate(0px, -100vh);
    }
    .Navigation--bg.right {
      right: 0;
      transform: translate(0px, 100vh);
      width: 100%;
      @media screen and (min-width: $screen-size-desktop) {
        width: 50%;
      }
      .Navigation--content--contact {
        position: fixed;
        bottom: 20px;
        right: $padding-mobile;
        letter-spacing: $font-letter-spacing-standard;
        text-transform: uppercase;
        font-size: $font-body-standard;
        cursor: pointer;
        @media screen and (min-width: $screen-size-desktop) {
          right: 30px;
          font-size: $font-body-standard;
        }
      }
      .Navigation--content--language {
        cursor: pointer;
        position: fixed;
        font-size: $font-body-standard;
        bottom: 20px;
        left: $padding-mobile;
        letter-spacing: $font-letter-spacing-standard;
        text-transform: uppercase;
        @media screen and (min-width: $screen-size-desktop) {
          font-size: $font-body-standard;
          left: 30px;
        }
      }
    }
    .MainMenu--content--left, .MainMenu--content--right {
      position: relative;
      width: 50%;
      overflow: hidden;
    }
    .MainMenu--content--left, .Navigation--bg.left  {
      display: none;
      @media screen and (min-width: $screen-size-desktop) {
        display: block;
      }
    }
    .MainMenu--content--image {
      position: absolute;
      left: 50%;
      top: 42%;
      transform: translate(-50%,-50%);
      width: 25vw;
      clip-path: inset(0px 0px 100%);
      > img {
        width: 100%;
        height: auto;
      }
    }
    .MainMenu--content--text--item {
      position: absolute;
      font-size: 90px;
      font-family: 'Jost', sans-serif;
      font-style: italic;
      font-weight: 100;
      letter-spacing: $font-letter-spacing-standard;
      &:nth-of-type(1) {
        left: 35%;
        transform: translateX(-35%);
      }
      &:nth-of-type(2) {
        left: -2vw;
        top: 29%;
      }
      &:nth-of-type(3) {
        right: -2vw;
        top: 70%;
      }
    }
    .MainMenu--content--right {
      height: calc(100% - 155px);
      padding-right: 0px;
      padding-left: 25px;
      padding-top: 45px;
      display: flex;
      align-items: left;
      justify-content: left;
      width: 100%;
      flex-direction: column;
      @media screen and (min-width: $screen-size-desktop) {
        width: 50%;
        align-items: center;
        justify-content: right;
        padding-right: 30px;
        padding-top: 0px;
        flex-direction: row;
      }
      ul {
        max-width: unset;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 300px;
        }
      }
      .MainMenu--content--image--mobile {
        clip-path: inset(0px 0px 100%);
        > img {
          width: 100px;
        }
        @media screen and (min-width: $screen-size-desktop) {
          display: none;
        }
      }
    }
    .MainMenu--content--list--item {
      font-family: 'Jost', sans-serif;
      list-style-type: none;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px;
      line-height: $font-line-height-standard;
      text-align: left;
      letter-spacing: $font-letter-spacing-standard;
      max-width: 300px;
      @media screen and (min-width: $screen-size-desktop) {
        text-align: right;
        font-size: 32px;
        margin-bottom: 60px;
        max-width: unset;
      }
    }
  
  }
}

