@import '../scss/_variables.scss';

.lightMode {
  &.WorkshopCulture {
    // background: linear-gradient(#B9CCD9, #BAC9D8);
    background-color: $bg-gradient-light-1;
    color: $font-color-layout-light;
    button {
      border: 1px solid $font-color-layout-light;
    }
  
    .gallery-item--circle {
      //background: radial-gradient(circle at 50% 100%, $bg-gradient-light-5 10%, rgba(101, 110, 135, 0) 70%);
      background: radial-gradient(circle at 50% 100%, $bg-gradient-light-3 10%, rgba(101, 110, 135, 0) 70%);
      color: $font-color-layout-light;
      button {
        border: 1px solid $font-color-layout-light;
      }
    }
  }
}

.darkMode {
  &.WorkshopCulture {
    background-color: $bg-gradient-dark-1;
    color: $font-color-layout-dark;
    button {
      border: 1px solid $font-color-layout-dark;
    }
  
    .gallery-item--circle {
      background: radial-gradient(circle at 50% 100%, #505876 10%, rgba(101, 110, 135, 0) 70%);
      color: $font-color-layout-dark;
      button {
        border: 1px solid $font-color-layout-dark;
      }
    }
  }
}

.WorkshopCulture {
  .WorkshopCulture--SlideWrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: $padding-mobile;
    padding-right: $padding-mobile;
    padding-top: 110px;
    @media screen and (min-width: $screen-size-desktop) {
      width: 100%;
      height: 100vh;
      flex-direction: row;
      padding-left: $padding-desktop-s;
      padding-right: $padding-desktop-s;
      padding-top: 0px;
    }
    .WorkshopCulture--SlideWrapper--Intro {
      margin-right: 5vw;
      padding-top: 0px;
      padding-bottom: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (min-width: $screen-size-desktop) {
        margin-right: 1vw;
        padding-bottom: 12px;
        padding-top: 12px;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        padding-bottom: 0px;
        padding-top: 35px;
      }
      p {
        line-height: $font-line-height-standard;
        max-width: unset;
        margin-bottom: $padding-mobile;
        font-size: $font-body-small-mobile;
        
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 35vw;
          font-size: $font-body-workshop;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          max-width: 28vw;
          font-size: $font-body-standard;
        }
      }
      h2 {
        font-size: $font-quote;
        margin-bottom: $padding-desktop-s;
        line-height: $font-line-height-standard;
        font-style: italic;
        max-width: unset;
        font-weight: 300;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 50vw;
          margin-bottom: $padding-mobile;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          max-width: 28vw;
          margin-bottom: $padding-desktop-s;
        }
        span {
          display: block;
          font-size: $font-body-standard;
          opacity: 0.6;
          margin-top: 12px;
        }
      }
      h1 {
        margin-bottom: $padding-desktop-s;
        font-family: 'Jost', sans-serif;
        font-size: $font-header-big;
        text-transform: capitalize;
      }
      button {
        display: inline-block;
        width: fit-content;
        border-radius: 60px;
        padding: 12px;
      }
    }
    .gallery-item {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 0px;
      flex-shrink: 0;
      flex-basis: auto;
      flex-direction: column;
      @media screen and (min-width: $screen-size-desktop) {
        width: 55vw;
        height: 75vh;
        margin-top: calc(110px);
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        width: 65vw;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        width: 40vw;
      }
      div {
        width: 100%;
        height: 35vh;
        background-size: cover;
        @media screen and (min-width: $screen-size-desktop) {
          width: 100%;
          height: 100%;
        }
      }
      &.description {
        padding: $padding-mobile 0px;
        justify-content: end;
        @media screen and (min-width: $screen-size-desktop) {
          padding: $padding-desktop-s;
        }
        h3 {
          text-transform: uppercase;
          letter-spacing: $font-letter-spacing-standard;
          line-height: $font-line-height-standard;
          margin-bottom: 12px;
          font-size: $font-body-workshop;
          @media screen and (min-width: $screen-size-desktop) {
            max-width: 40vw;
            line-height: 1.3;
            font-size: $font-body-standard;
          }
          @media screen and (min-width: $screen-size-desktop-l) {
            max-width: 370px;
          }
          span {
            font-style: italic;
          }
        }
        p {
          line-height: $font-line-height-standard;
          font-size: $font-body-workshop;
          margin-bottom: 15px;
          i {
            font-style: italic;
          }
          @media screen and (min-width: $screen-size-desktop) {
            max-width: 40vw;
            line-height: 1.3;
            font-size: $font-body-standard;
            margin-bottom: 12px;
          }
          @media screen and (min-width: $screen-size-desktop-l) {
            max-width: 370px;
            line-height: $font-line-height-standard;
            font-size: $font-body-workshop;
          }
        }
        span {
          font-size: $font-body-small-mobile;
          text-transform: uppercase;
          letter-spacing: $font-letter-spacing-standard;
        }
       
      }
      .gallery-item--circle {
        border-radius: 50%;
        width: calc(100vw - 2* $padding-mobile);
        height: calc(100vw - 2* $padding-mobile);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Noto Sans', sans-serif;
        margin-bottom: 100px;
        @media screen and (min-width: $screen-size-desktop) {
          width: 75vh;
          height: 100%;
          margin-bottom: 0px;
        }
        h1 {
          font-size: $font-body-standard-ex;
          line-height: $font-line-height-standard;
          text-transform: uppercase;
          letter-spacing: $font-letter-spacing-standard;
          font-weight: 300;
          margin-bottom: 20px;
          margin-top: 40px;
          text-align: center;
          font-size: $font-body-standard-ex;
          @media screen and (min-width: $screen-size-desktop) {
            font-size: $font-quote;
          }
        }
        button {
          border-radius: 60px;
          padding: $font-body-workshop;
          font-size: $font-body-workshop;
        }
      }
    }
  }
}