@import '../scss/_variables.scss';

// Color setting for dark/light Mode
.lightMode {
  &.Navigation {
    color: $font-color-layout-light;
    background-color: rgba(255,255,255,0.8);
    @media screen and (min-width: $screen-size-desktop) {
      background-color: unset;
    }
  }
  .Navigation--wrapper {
    color: $font-color-layout-light;
  }
  .BtnMenu:before {
    background-color: $font-color-layout-light;
  }
}

.darkMode {
  &.Navigation {
    color: $font-color-layout-dark;
    background-color: rgba(31,31,32,0.8);
    @media screen and (min-width: $screen-size-desktop) {
      background-color: unset;
    }
  }
  .Navigation--wrapper {
    color: $font-color-layout-dark;
  }
  .BtnMenu:before {
    background-color: $font-color-layout-dark;
  }
}


// Navigation styling 
.Navigation {
  width: 100vw;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  @media screen and (min-width: $screen-size-desktop) {
    height: 110px;
  }
}

.Navigation--wrapper {
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  width: 100%;
  height: 100%;
  padding: 20px 25px;
  font-family: 'Noto Sans', sans-serif;
  text-transform: uppercase;
  @media screen and (min-width: $screen-size-desktop) {
    padding: 20px 30px;
  }
}

