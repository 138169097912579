@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

$circle-diametre-large: 550px;
$circle-diametre: 400px;
$circle-diametre-small: 90vw;

// Color setting for dark/light Mode
.lightMode {
  .WorkshopContainer {
    background: linear-gradient($bg-gradient-light-2, $bg-gradient-light-3);
    .WorkshopContainer--title {
      color: $font-color-layout-light;
    }
    .half-circle {
      &.left {
        background: radial-gradient(circle at 50% 0%, $bg-gradient-light-1 10%, rgba(189,171,192,0) 65%);
        @media screen and (min-width: $screen-size-desktop) {
          background: radial-gradient(circle at 50% 0%, $bg-gradient-light-1 10%, rgba(189,171,192,0) 65%);
        }
      }
      &.right {
        background: radial-gradient(circle at 50% 100%, $bg-gradient-light-3 10%, rgba(101,110,135,0) 65%);
      }
    }
    .WorkshopContainer--slideHandle {
      &:nth-of-type(1) {
        div {
          background: radial-gradient(circle at 50% 0%, rgba(214,207,207,0.6) 10%, rgba(189,171,192,0) 70%);
          background-color: $bg-gradient-light-3;
        }
      }
      &:nth-of-type(5) {
        div {
          background: radial-gradient(circle at 50% 0%, $bg-gradient-light-3 10%, $font-color-layout-light 70%);
          background-color: $font-color-layout-light;
        }
      }
    }
  }
  .line {
    &.left {
      > div {
        background-color: $bg-gradient-light-1;
      }
    }
    &.right {
      > div {
        background-color: $font-color-layout-light;
      }
    }
  }
}

.darkMode {
  .WorkshopContainer {
    background: linear-gradient($bg-gradient-dark-2, $bg-gradient-dark-4);
    .WorkshopContainer--title {
      color: $font-color-layout-dark;
    }
    .half-circle {
      &.left {
        background: radial-gradient(circle at 50% 0%, rgba(214,207,207,0.6) 10%, rgba(189,171,192,0) 65%);
      }
      &.right {
        background: radial-gradient(circle at 50% 100%, $bg-gradient-dark-2 10%, rgba(101,110,135,0) 65%);
      }
    }
    .WorkshopContainer--slideHandle {
      &:nth-of-type(1) {
        div {
          background: radial-gradient(circle at 50% 0%, rgba(214,207,207,0.6) 10%, rgba(189,171,192,0) 70%);
          background-color: $bg-gradient-dark-3;
        }
      }
      &:nth-of-type(5) {
        div {
          background: radial-gradient(circle at 50% 0%, $bg-gradient-dark-5 10%, rgba(189,171,192,0) 70%);
          background-color: $bg-gradient-dark-2;
        }
      }
    }
  }
  .line {
    &.left {
      > div {
        background-color: rgba(214,207,207,0.6);
      }
    }
    &.right {
      > div {
        background-color: $bg-gradient-dark-2;
      }
    }
  }
}

.WorkshopContainer {
  @include flex-cover;

  align-items: center;
  max-width: 100vw;
  padding: $padding-desktop-s;
  overflow: hidden;
  .WorkshopContainer--Center {
    .WorkshopContainer--title {
      font-family: 'Jost', sans-serif;
      font-size: $font-header-big;
      text-align: center;
      @media screen and (min-width: $screen-size-desktop) {
        top: 0;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        font-size: $font-header-big;
      }
      @media screen and (min-width: $screen-size-desktop-xl) {
        font-size: $font-header-extra-big;
        top: $padding-mobile;
      }
      > span {
        @include font-small-header-standard;
        display: none;
        justify-content: space-between;
        margin-top: $padding-mobile;
        @media screen and (min-width: $screen-size-desktop) {
          margin-top: 12px;
          display: block;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          margin-top: $padding-mobile;
        }
        @media screen and (min-width: $screen-size-desktop-xl) {
          font-size: $font-body-standard;
        }
      }
    }
    
    .WorkshopContainer--wrapper {
      display: flex;
      margin-top: 50px;
      @media screen and (min-width: $screen-size-desktop) {
        margin-top: $padding-desktop-m;
      }
      @media screen and (min-width: $screen-size-desktop-xl) {
        margin-top: 150px;
      }
    }

  }
 
  .WorkshopContainer--dropArea {
    display: flex;
    justify-content: center;
    .WorkshopContainer--slideHandle {
      display: none;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: $screen-size-desktop) {
        display: flex;
      }
      div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      &:hover > div {
        transform: scale(1.1);
        transition: all .3s cubic-bezier(.77,.2,.05,1);
      }
    
    }
    .WorkshopContainer--dropArea--wrapper {
      position: relative;
      width: $circle-diametre-small;
      height: $circle-diametre-small;
      font-family: 'Noto Sans', sans-serif;
      background-size: 110%;
      background-position: center;
      @media screen and (min-width: $screen-size-small) {
        width: $circle-diametre;
        height: $circle-diametre;
      }
      @media screen and (min-width: $screen-size-desktop) {
        background-image: none !important;
      }
      @media screen and (min-width: $screen-size-desktop-xl) {
        width: $circle-diametre-large;
        height: $circle-diametre-large;
      }
    }
    .half-circle {
      position: absolute;
      display: inline-block;
      width: $circle-diametre-small;
      height: calc($circle-diametre-small / 2);
      top: calc($circle-diametre-small / 4);
      transform: rotate(90deg);
      @media screen and (min-width: $screen-size-small) {
        top: calc($circle-diametre / 4);
        width: $circle-diametre;
        height: calc($circle-diametre / 2);
      }
      @media screen and (min-width: $screen-size-desktop-xl) {
        top: calc($circle-diametre-large / 4);
        width: $circle-diametre-large;
        height: calc($circle-diametre-large / 2);
      }
      &.left {
        left: calc(($circle-diametre-small / -4) - 1px);
        border-radius: 0 0 50% 50% / 0 0 100% 100%;
        @media screen and (min-width: $screen-size-small) {
          left: calc(($circle-diametre / -4) - 1px);
        }
        @media screen and (min-width: $screen-size-desktop-xl) {
          left: calc(($circle-diametre-large / -4) - 1px);
        }
      }
      &.right {
        right: calc(($circle-diametre-small / -4) - 1px);
        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
        margin-left: calc($circle-diametre-small / -2);
        @media screen and (min-width: $screen-size-small) {
          right: calc(($circle-diametre / -4) - 1px);
          margin-left: calc($circle-diametre / -2);
        }
        @media screen and (min-width: $screen-size-desktop-xl) {
          right: calc(($circle-diametre-large / -4) - 1px);
          margin-left: calc($circle-diametre-large / -2);
        }
      }
    }
  }
}

.line {
  position: relative;
  display: none;
  width: 200px;
  align-items: center;
  &.left {
    margin-right: -($padding-desktop-s);
    > div {
      background-color: rgba(214,207,207,0.6);
    }
    > div::before {
      content: attr(data-value);
      position: absolute;
      left: 15px;
      top: 41%;
    }
  }
  &.right {
    margin-left: -($padding-desktop-s);
    > div {
      background-color: $bg-gradient-dark-2;
    }
    > div::before {
      content: attr(data-value);
      right: 15px;
      top: 41%;
    }
  }
  > div {
    width: 100%;
    height: 1px;
    
    &::before {
      @include font-small-header-standard;

      position: absolute;
      font-weight: 800;
    }
  }
  @media screen and (min-width: $screen-size-desktop) {
    display: flex;
  }
}

