@import '../scss/_variables.scss';

@mixin absolute-center {  
  position: absolute;
  top: 50%;  
  left: 50%; 
}

@mixin fixed-cover {  
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
}

@mixin flex-cover {  
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-column-center{  
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin background-cover {  
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: background-image 0 ease;
  transition: background-image 0 ease;
}

@mixin font-body-standard {  
  font-family: 'Noto Sans', sans-serif;
  font-size: $font-body-standard; 
  line-height: $font-line-height-standard;
  // letter-spacing: $font-letter-spacing-standard;
}

@mixin font-small-header-standard {  
  font-family: 'Jost', sans-serif;
  font-size: $font-body-standard-ex;
  letter-spacing: $font-letter-spacing-standard;
  text-transform: uppercase;
}

@mixin section-padding-standard {  
  padding-top: $section-space-mobile; 
  padding-bottom: $section-space-mobile; 
  padding-left: $padding-mobile;
  padding-right: $padding-mobile;
}