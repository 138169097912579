@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.Credits {
  &.lightMode {
    background-color: $bg-gradient-light-1;
    color: $darkblue-color;
  }
}

.Credits {
  &.darkMode {
    background-color: $bg-gradient-dark-1;
    color: $lightgrey-color;
  }
}

.Credits {
  @include flex-cover;
  position: fixed;
  align-items: center;

  left: 0;
  bottom: -100vh;
  transition: bottom 0.3s ease-in;
  z-index: 999;

  &.Show {
    bottom: 0;
  }

  .Credits--btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    text-transform: uppercase;
    letter-spacing: $font-letter-spacing-standard;
    font-size: $font-body-standard;
  }

  .Credits--wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: unset;
    padding: 50px;
    @media screen and (min-width: $screen-size-desktop) {
      flex-direction: row;
      max-height: 45vh;
    }
    .Credits--wrapper--text {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
     
      h1 {
        text-transform: uppercase;
        letter-spacing: $font-letter-spacing-standard;
        font-size: $font-body-standard;
        line-height: $font-line-height-standard;
        margin-bottom: 5px;
      }
      ul {
        margin-bottom: 15px;
        li {
          font-size: $font-body-standard;
          line-height: $font-line-height-standard;
          a {
            text-decoration: underline;
          }
        }
      }
      p {
        font-size: $font-body-standard;
        line-height: $font-line-height-standard;
      }

      .Credits--wrapper--text--area {
        min-width: 20vw;
      }

      .italic {
        font-style: italic;
        opacity: 0.6;
        margin-top: 20px;
      }
     
    }
    .Credits--wrapper--image {
      width: 100%;
      height: 100%;
      img {
        width: 45%;
        height: 100%;
        object-fit: contain;
        @media screen and (min-width: $screen-size-desktop) {
          width: 100%;
        }
      }
    }

  }
}

