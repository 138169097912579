@import '../scss/_variables.scss';

// Color setting for dark/light Mode
.lightMode {
  &.WorkshopTherapeutic {
    // background: linear-gradient(#B9CCD9, #BAC9D8);
    background-color: $bg-gradient-light-1;
    color: $font-color-layout-light;
    button, .gallery-item-actionCircle {
      border: 1px solid $font-color-layout-light;
    }
  
    .gallery-item--circle {
      background: radial-gradient(circle at 50% 100%, white 10%, rgba(189, 171, 192, 0) 70%);
      color: $font-color-layout-light;
      button {
        border: 1px solid $font-color-layout-light;
      }
    }
  }
}

.darkMode {
  &.WorkshopTherapeutic {
    // background: linear-gradient($bg-gradient-dark-1, $bg-gradient-dark-2);
    background-color: $bg-gradient-dark-1;
    color: $font-color-layout-dark;
    button, .gallery-item-actionCircle {
      border: 1px solid $font-color-layout-dark;
    }
  
    .gallery-item--circle {
      //background: radial-gradient(circle at 50% 100%, $bg-gradient-dark-5 10%, rgba(101, 110, 135, 0) 70%);
      background: radial-gradient(circle at 50% 100%, rgba(214, 207, 207, 0.6) 10%, rgba(189, 171, 192, 0) 70%);
      color: $font-color-layout-dark;
      button {
        border: 1px solid $font-color-layout-dark;
      }
    }
  }
}

.WorkshopTherapeutic {
  .WorkshopTherapeutic--SlideWrapper {
    display: flex;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    padding-left: $padding-mobile;
    padding-right: $padding-mobile;
    padding-top: 110px;
    @media screen and (min-width: $screen-size-desktop) {
      width: 100%;
      height: 100vh;
      flex-direction: row;
      padding-left: $padding-desktop-s;
      padding-right: $padding-desktop-s;
      padding-top: 0px;
    }
    .gallery-item {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      margin-bottom: 100px;
      flex-shrink: 0;
      flex-basis: auto;
      flex-direction: column;
      justify-content: center;
      font-family: 'Noto Sans', sans-serif;
      @media screen and (min-width: $screen-size-desktop) {
        width: 65vw;
        height: 75vh;
        margin-top: calc(110px);
        max-width: 1000px;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        width: 55vw;
      }
      .gallery-item-wrapper  {
        max-width: 100%;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 600px;
        }
      }
      &.layout-1 {
        padding-right: 0px;
        @media screen and (min-width: $screen-size-desktop) {
          padding-right: 150px;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          padding-right: 0px;
        }
        > .gallery-item-wrapper  > div:nth-of-type(1) {
          width: 100%;
          height: 30vh;
          background-size: cover;
          background-position: 50% 25%;
          margin-bottom: $padding-mobile;
          @media screen and (min-width: $screen-size-desktop) {
            height: 40vh;
          }
        }
        .gallery-item--Description {
          display: flex;
          flex-direction: column;
          margin-bottom: $padding-mobile;
          gap: 30px;
          @media screen and (min-width: $screen-size-desktop) {
            flex-direction: row;
            align-items: center;
          }
          h1 {
            font-size: $font-header-medium;
            font-family: 'Jost', sans-serif;
            @media screen and (min-width: $screen-size-desktop) {
              flex-direction: row;
            }
            @media screen and (min-width: $screen-size-desktop-l) {
              font-size: $font-header-big;
            }
          }
          p {
            font-size: $font-body-standard-ex;
            max-width: unset;
            font-weight: 300;
            line-height: $font-line-height-standard;
            font-style: italic;
            @media screen and (min-width: $screen-size-desktop) {
              max-width: 200px;
              font-size: $font-body-standard;
            }
            @media screen and (min-width: $screen-size-desktop-l) {
              font-size: $font-body-standard-ex;
            }
        
          }
        }
      }
      &.layout-2 {
        justify-content: center;
        flex-direction: column-reverse;
        padding-right: 0px;
        @media screen and (min-width: $screen-size-desktop) {
          flex-direction: column;
          padding-right: 150px;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          padding-right: 0px;
        }
        h1 {
          max-width: 100%;
          font-size: $font-header-medium;
          font-family: 'Jost', sans-serif;
          margin-bottom: 30px;
          order: 1;
          @media screen and (min-width: $screen-size-desktop) {
            order: unset;
            font-size: $font-header-big;
          }
          @media screen and (min-width: $screen-size-desktop-l) {
            max-width: 700px;
            font-size: $font-header-extra-big;
          }
        }
        > div:nth-of-type(1) {
          max-width: 90%;
          display: flex;
          flex-direction: row;
          gap: 30px;

          @media screen and (min-width: $screen-size-desktop) {
            max-width: 500px;
            gap: 50px;
          }
          h2 {
            font-size: $font-body-standard-ex;
            font-style: italic;
            font-weight: 300;
            line-height: $font-line-height-standard;
            display: inline;
            @media screen and (min-width: $screen-size-desktop) {
              font-size: $font-body-standard;
            }
            @media screen and (min-width: $screen-size-desktop-l) {
              font-size: $font-body-standard-ex;
            }
          }
         
        }
        > div:nth-of-type(2) {
          position: relative;
          width: 200px;
          height: 100px;
          margin-top: 15px;
          margin-bottom: 25px;
          @media screen and (min-width: $screen-size-desktop) {
            margin-bottom: 0px;
          }
          > div {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
        }
      }
      &.layout-3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 80px;
        .gallery-item-wrapper {
          display: flex;
          flex-direction: column;
          max-width: 800px;
          h1 {
            font-size: $font-header-medium;
            font-family: 'Jost', sans-serif;
            @media screen and (min-width: $screen-size-desktop) {
              font-size: $font-header-big;
            }
            @media screen and (min-width: $screen-size-desktop-l) {
              font-size: $font-header-extra-big;
              margin-bottom: 30px;
            }
          }
          > div {
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media screen and (min-width: $screen-size-desktop) {
              flex-direction: row;
            }
            > div:nth-of-type(1) {
              width: 100%;
              height: 40vh;
              background-size: cover;
              background-position: center;
              display: none;
              @media screen and (min-width: $screen-size-desktop) {
                width: 30vw;
                height: 100%;
                display: block;
              }
              @media screen and (min-width: $screen-size-desktop-l) {
                width: 45vw;
              }
            }
            > div:nth-of-type(2) {
              display: flex;
              flex-direction: column;
              gap: $padding-mobile;
              @media screen and (min-width: $screen-size-desktop) {
                flex-direction: column;
              }
              > div {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                gap: 10px;
                @media screen and (min-width: $screen-size-desktop-l) {
                  justify-content: center;
                }
                > div:nth-of-type(1) {
                  width: 150px;
                  height: 150px;
                  background-size: cover;
                  background-position: center;
                  border-radius: 50%;
                  flex-shrink: 0;
                  @media screen and (min-width: $screen-size-desktop) {
                    display: none;
                  }
                  @media screen and (min-width: $screen-size-desktop-l) {
                    display: block;
                  }
                }
                p {
                  padding-left: 10px;
                  width: 200px;
                  font-style: italic;
                  line-height: $font-line-height-standard;
                  font-size: $font-body-standard-ex;
                  font-weight: 300;
                  @media screen and (min-width: $screen-size-desktop) {
                    width: 250px;
                    font-size: $font-body-standard;
                  }
                  @media screen and (min-width: $screen-size-desktop-l) {
                    width: 200px;
                    font-size: $font-body-standard-ex;
                  }
                }
              }
            }
          }
         


        }
       
        
      }
     .gallery-item-actionCircle {
       width: 60px;
       height: 60px;
       border-radius: 50%;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       font-size: $font-body-standard;
     }
      .gallery-item--circle {
        width: calc(100vw - 2* $padding-mobile);
        height: calc(100vw - 2* $padding-mobile);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Noto Sans', sans-serif;
        //margin-bottom: $padding-desktop-s;
        @media screen and (min-width: $screen-size-desktop) {
          width: 75vh;
          height: 75vh;
          margin-bottom: 0px;
        }
        h1 {
          font-size: $font-body-standard;
          line-height: $font-line-height-standard;
          text-transform: uppercase;
          letter-spacing: 5px;
          font-weight: 300;
          margin-bottom: 20px;
          text-align: center;
          font-size: $font-body-standard-ex;
          @media screen and (min-width: $screen-size-desktop) {
            font-size: $font-quote;
          }
        }
        button {
          border-radius: 60px;
          padding: 14px;
          font-size: $font-body-workshop;
        }
      }
    }
    .WorkshopTherapeutic--SlideWrapper--Intro {
      margin-right: 5vw;
      padding-bottom: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (min-width: $screen-size-desktop) {
        padding-bottom: 12px;
        padding-top: 12px;
      }
      @media screen and (min-width: $screen-size-desktop-l) {
        padding-bottom: 0px;
        max-width: 45vw ;
        padding-top: 35px;
      }
      p {
        line-height: $font-line-height-standard;
        max-width: unset;
        margin-bottom: $padding-mobile;
        font-size: $font-body-small-mobile;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 50vw;
          font-size: $font-body-workshop;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          max-width: 28vw;
          font-size: $font-body-standard;
        }
      }
      h2 {
        font-size: $font-quote;
        margin-bottom: $padding-desktop-s;
        line-height: $font-line-height-standard;
        font-style: italic;
        max-width: unset;
        font-weight: 300;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 50vw;
          margin-bottom: $padding-mobile;
        }
        @media screen and (min-width: $screen-size-desktop-l) {
          max-width: 28vw;
          margin-bottom: $padding-desktop-s;
        }
        span {
          display: block;
          font-size: $font-body-standard;
          opacity: 0.6;
          margin-top: 12px;
        }
      }
      h1 {
        margin-bottom: $padding-desktop-s;
        font-family: 'Jost', sans-serif;
        font-size: $font-header-big;
        text-transform: capitalize;
        @media screen and (min-width: $screen-size-desktop) {
          max-width: 300px;
        }
      }
      button {
        display: inline-block;
        width: fit-content;
        border-radius: 60px;
        padding: 12px;
      }
    }
  }
}