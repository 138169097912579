@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.Preloader {
  @include fixed-cover;

  background-color: $bg-gradient-dark-1;

  z-index: 999;

  .Logo--wrapper {
    @include absolute-center;

    transform: translate(-35%, -35%);
    transform-origin: center center;
    color: $font-color-layout-dark;
    scale: 1.3;

    z-index: 1000;
  }

  .Preloader--Circle {
    @include absolute-center;

    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle at 50% 100%,$bg-gradient-dark-2 15%, $bg-gradient-dark-1 65%);
  }

}