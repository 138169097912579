@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

// Color setting for dark/light Mode
.lightMode {
  .About {
    background: linear-gradient($bg-gradient-light-1, $bg-gradient-light-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
  .About .About--Contact--Circle {
    border: 1px solid $font-color-layout-light;
  }
}

.darkMode {
  .About {
    background: linear-gradient($bg-gradient-dark-1, $bg-gradient-dark-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
  .About .About--Contact--Circle {
    border: 1px solid $font-color-layout-dark;
  }
}

.About {
  @include flex-cover;
  @include font-body-standard;
  @include section-padding-standard;

  
  .About--wrapper {
    p, .Collapsible{
      position: relative;
      max-width: 800px;
      margin-bottom: $padding-mobile;
    }
    .Collapsible{
      display: flex;
      flex-direction: column-reverse;
    }
    .Collapsible__trigger {
      @include font-small-header-standard;
    }
    > p:nth-of-type(1) {
      @include font-small-header-standard;

      margin-bottom: $padding-mobile;
    }
  }
  @media screen and (min-width: $screen-size-desktop) {
    width: 100vw;
    padding-top: 0; 
    padding-bottom: 0;
    padding-left: $section-space-desktop-s;
    padding-right: $section-space-desktop-s;
  }
 
  .About--Contact {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $padding-desktop-s;
    @media screen and (min-width: $screen-size-desktop-l) {
      position: absolute;
      top: calc(50% - 40px);
      right: calc($section-space-desktop-s + 40px);
      margin-top: 0;
    }
    p {
      @include font-small-header-standard;

      max-width: 80px;
      margin-bottom: 0px;
    }

    .About--Contact--Circle {
      @include flex-column-center;

      width: 80px;
      height: 80px;
     
      margin-right: 15px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

