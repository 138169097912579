@import '../scss/_variables.scss';

.loader--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3E5871;
  position: absolute;
  width: 100vw;
  height: 100vh;
  .Logo {
    color: white;
  }
}


.Home.darkMode {
  color: $font-color-layout-dark;
}

.Home.lightMode {
  color: $font-color-layout-light;
}








