@import '../scss/_variables.scss';



//Color settings are beneath 


#ModeToggler {
  position: fixed;
  bottom: 15px;
  left: 25px;
  z-index: 10;
  @media screen and (min-width: $screen-size-desktop) {
    bottom: 35px;
  }
  .ModeToggler--label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    border-radius: 120px;
    transition: all .7s cubic-bezier(.77,.2,.05,1);
    input {
      display: none;
    }
    .ModeToggler--btn {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      display: block;
      transition: all 0.3s;
      border-radius: 120px;
      cursor: pointer;
      &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        border-radius: 100%;
        display: block;
        left: 6px;
        bottom: 4px;
        // background-color: $bg-gradient-dark-1;
        transition: all 0.3s;
      }
    }
    .ModeToggler--iconDay,.ModeToggler--iconNight {
      z-index: 11;
      position: absolute;
      top: 50%;
      width: 14px;
      height: 14px;
      transform: translateY(-50%);
      opacity: 1;
      border-radius: 50%;
      background-color: #D1E0EA;
    }
    .ModeToggler--iconDay {
      right: 10px;
      border: 1px solid #D1E0EA;
    }
    .ModeToggler--iconNight {
      left: 10px;
      // border: 1px solid #3E5871;
      background-color: $bg-gradient-dark-2;

    }
  }
}


// Color setting for dark/light Mode
.lightMode {
  &#ModeToggler {
    .ModeToggler--btn{
      background-color: rgba(255,255,255,0.7);
      background-color: $bg-gradient-light-1;
    }
    .ModeToggler--btn:before  {
      transform: translate(26px, 0);
      background-color: rgba(255,255,255,0.9);
    }
    .ModeToggler--iconDay {
      opacity: 0;
      z-index: 0;
    }
  }
}

.darkMode {
  &#ModeToggler {
    .ModeToggler--btn{
      background-color: rgba(31,31,32,0.7);
    }
    .ModeToggler--btn:before {
      transform: translate(0px, 0);
      background-color: $bg-gradient-dark-2;
    }
    .ModeToggler--iconNight {
      opacity: 0;
      z-index: 0;
    }
  }
}


#ModeToggler:hover {
  &.darkMode {
    .ModeToggler--btn:before {
      transform: translate(0px, 0) scale(1.1);
      transform-origin: center center;
    }
  }
  &.lightMode {
    .ModeToggler--btn:before {
      transform: translate(26px, 0) scale(1.1);
      transform-origin: center center;
    }
  }
}
