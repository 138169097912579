@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.lightMode {
  .Contact {
    background: linear-gradient($bg-gradient-light-2 45%, $bg-gradient-light-1);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
  .Contact--wrapper--contact {
    background: none;

    &::before {
      @media screen and (min-width: $screen-size-desktop) {
        background: linear-gradient( $bg-gradient-light-1, $bg-gradient-light-2);
      }
    }

    @media screen and (min-width: $screen-size-desktop) {
      background: radial-gradient(circle at 50% 100%, $bg-gradient-light-3 10%, rgba(101, 110, 135, 0) 70%);
    }
  }
  .Contact--wrapper--contact button {
    border: 1px solid $font-color-layout-light;
  }
  .About--Event {
    color: $bg-gradient-light-4;
    @media screen and (min-width: $screen-size-desktop) {
      color: $bg-gradient-light-1;
    }
  }
  .About--Event--Circle {
    border: 1px solid $bg-gradient-light-4;
    @media screen and (min-width: $screen-size-desktop) {
      border: 1px solid $bg-gradient-light-1;
    }
  }
}

.darkMode {
  .Contact {
    background: linear-gradient($bg-gradient-dark-2 45%, $bg-gradient-dark-1);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
  .Contact--wrapper--contact {
    background: none;

    &::before {
      @media screen and (min-width: $screen-size-desktop) {
        background: linear-gradient( $bg-gradient-dark-1, $bg-gradient-dark-2);
      }
    }

    @media screen and (min-width: $screen-size-desktop) {
      background: radial-gradient(circle at 50% 100%, $bg-gradient-dark-2 10%, rgba(101, 110, 135, 0) 70%);
    }
  }
  .Contact--wrapper--contact button {
    border: 1px solid $font-color-layout-dark;
  }
  .About--Event {
    color: $bg-gradient-dark-5;
  }
  .About--Event--Circle {
    border: 1px solid $bg-gradient-dark-5;
  }
}


.Contact {
  @include flex-column-center;
  width: 100vw;
  justify-content: flex-start;

  z-index: 5;
  overflow-x: clip;
  padding-bottom: 50px;
  @media screen and (min-width: $screen-size-desktop) {
    height: 100vh;
    justify-content: center;
    padding-left: 2vw;
    padding-bottom: 0px;
  }
  .Contact--wrapper {
    @include flex-column-center;
    
    flex-shrink: 0;
    @media screen and (min-width: $screen-size-desktop) {
      width: 75vw;
      height: 75vh;
    }
    @media screen and (min-width: $screen-size-desktop-l) {
      width: 55vw;
    }
    > div {
      @include background-cover;
    }
    .Contact--wrapper--contact {
      &::before {
        display: none;
        @media screen and (min-width: $screen-size-desktop) {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          border-radius: 50%;
          z-index: -1;
        }
      }

      @include flex-column-center;

      width: 160vw;
      height: 160vw;
      border-radius: 50%;
      font-family: 'Noto Sans', sans-serif;
      margin-top: -15vh;
      margin-bottom: 5vh;
      @media screen and (min-width: $screen-size-desktop) {
        width: 75vh;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
      }
      h1 {
        @include font-small-header-standard;
        text-transform: capitalize;
        font-size: $font-header-big;

        margin-bottom: 20px;
      }
      button {
        margin: 5px;
        border-radius: 60px;
        padding: 10px 14px;
        font-size: $font-body-standard;
        text-transform: capitalize;
        @media screen and (min-width: $screen-size-desktop) {
          font-size: $font-body-standard;
          padding: 14px 18px;
          margin: 8px;
        }
      }
      .About--Event {
        display: flex;
        position: absolute;
        bottom: $padding-desktop-m;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        p {
          @include font-small-header-standard;
          font-weight: 600;
        }
        .About--Event--Circle {
          @include flex-column-center;

          width: $padding-mobile;
          height: $padding-mobile;
          margin-left: 8px; 
          border-radius: 50%;
          @media screen and (min-width: $screen-size-desktop) {
            width: 40px;
            height: 40px;
            margin-left: 15px; 
          }           
        }
      }
    }
  }
}


