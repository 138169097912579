@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.lightMode {
  .gallery-wrap {
    background: linear-gradient($bg-gradient-light-3, $bg-gradient-light-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
}

.darkMode {
  .gallery-wrap {
    background: linear-gradient($bg-gradient-dark-3, $bg-gradient-dark-2);
    @media screen and (min-width: $screen-size-desktop) {
      background: unset;
    }
  }
}


.gallery-wrap {
  position: relative;
  display: flex;
  width: 100vw;
  flex-direction: column;
  // z-index: 0;
  @media screen and (min-width: $screen-size-desktop) {
    height: 100vh;
  }
  .gallery-item {
    @include flex-column-center;
    width: 100vw;
    height: 50vh;
    flex-shrink: 0;
  
    // z-index: 0;
    @media screen and (min-width: $screen-size-desktop) {
      position: absolute;
      z-index: -1;
    }
    &:nth-of-type(1) {
      width: 100vw;
      height: 40vh;
      margin: 0 auto;
      @media screen and (min-width: $screen-size-desktop) {
        top: -30vh;
        left: 50px;
        width: 25vw;
        height: 25vw;
      }
    }
    &:nth-of-type(2) {
      width: 100vw;
      height: 40vh;
      @media screen and (min-width: $screen-size-desktop) {
        top: -$section-space-mobile;
        right: $padding-desktop-s;
        width: 25vw;
        height: 25vw;
      }
    }
    &:nth-of-type(3) {
      width: 100vw;
      height: 40vh;
      @media screen and (min-width: $screen-size-desktop) {
        width: 30vw;
        height: 30vw;
        left: $padding-desktop-m;
        top: calc(-30vh + 35vh + $padding-desktop-m);
      }
    }
    &:nth-of-type(4) {
      display: none;
      @media screen and (min-width: $screen-size-desktop) {
        width: 30vw;
        height: 30vw;
        top: calc(-10vh + 30vh + $padding-desktop-m);
        right: $padding-desktop-m;
      }
    }
    @media screen and (min-width: $screen-size-desktop) {
      width: 45vw;
      height: 35vh;
      &:last-of-type {
        display: block;
      }
    }
    > div {
      @include background-cover;
      
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 0%;
      @media screen and (min-width: $screen-size-desktop) {
        border-radius: 50%;
        // border-radius: 5px;
      }
    }
  }
}


