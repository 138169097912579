@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

// Color setting for dark/light Mode
.lightMode {
  &.Trainings--content{
    background: $bg-gradient-light-1;
    background: $font-color-layout-light;
    color: $font-color-layout-light;
    color: $bg-gradient-light-1;
    button {
      @media screen and (min-width: $screen-size-desktop) {
        border: 1px solid $bg-gradient-light-1;
        background-color: $bg-gradient-light-1;
        background-color: $font-color-layout-light;
      }
    }
  }
}

.darkMode {
  &.Trainings--content {
    background: $bg-gradient-dark-1;
    color: $font-color-layout-dark;
    button {
      @media screen and (min-width: $screen-size-desktop) {
        border: 1px solid $font-color-layout-dark;
        background-color: $bg-gradient-dark-1;
      }
    }
  }
}


.Trainings--content {
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  transition: right 0.3s ease-out;
  // padding-bottom: 100px;
  z-index: 999;

  @media screen and (min-width: $screen-size-desktop) {
    width: 65vw;
    height: 100vh;
    right: -65vw;
    padding-top: 0;
    align-items: center;
    overflow: visible;
  }
  @media screen and (min-width: $screen-size-desktop-l) {
    width: 45vw;
    right: -45vw;
  }

  .Trainings-content-wrapper {
    position: relative;
    display: block;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 3rem);
    overflow-y: auto;
    @media screen and (min-width: $screen-size-desktop) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      padding-top: 0px;
      overflow: visible;
    }
    &::-webkit-scrollbar {
      display: none !important;
    }
    button {
      @include font-small-header-standard;
      font-size: $font-body-small-detail;

      visibility: hidden;
      position: fixed;
      top: 10px;
      right: 0;
      padding: 25px;
      border-radius: 50%;
      text-align: center;

      z-index: 1000;

      @media screen and (min-width: $screen-size-desktop) {
        position: absolute;
        width: 80px;
        height: 80px;
        left: -40px;
        top: 50%;
        padding: 0px;
      }
    }
    .Trainings--content--list {
      @include flex-cover;
      width: 100%;
      height: 100%;

      position: relative;
      max-width: 500px;
      padding: $padding-mobile $padding-mobile;
      @media screen and (min-width: $screen-size-desktop) {
        padding: $padding-desktop-s $padding-desktop-s $padding-desktop-s 75px;
        max-width: unset;
      }
      @media screen and (min-width: $screen-size-desktop-xl) {
        padding: $padding-desktop-s $padding-desktop-m $padding-desktop-s $padding-desktop-l;
      }
      h1 {
        @include font-small-header-standard;
        text-transform: capitalize;
        font-size: $font-header-small;

        margin-bottom: 15px; 
        margin-top: 50px; 
        @media screen and (min-width: $screen-size-desktop) {
          margin-bottom: $padding-mobile; 
          font-size: $font-header-small;
        }
      }
      ul {
        font-size: $font-body-standard;
        
        @media screen and (min-width: $screen-size-desktop) {
          font-size: $font-body-workshop;
        }
        > ul {
          padding-left: $padding-mobile; 
          > li {
            list-style-type: circle;
            margin-top: 5px; 
          }
        }
        li {
          margin-top: 15px; 
          margin-bottom: 15px; 
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          line-height: $font-line-height-standard;
        
          p {
            margin-bottom: 5px;
          }
          span {
            display: block;
            margin-top: 2px;
            font-family: 'Noto Sans', sans-serif;
            text-transform: uppercase;
            letter-spacing: $font-letter-spacing-standard;
            font-size: 0.9rem;
            font-weight: 300;
            @media screen and (min-width: $screen-size-desktop) {
              font-size: 1.1rem;
            }
          }
          a {
            font-style: italic;
            font-weight: 300;
          }
        }
      }
    }

  }
  &.Show {
    right: 0;
    button {
      visibility: visible;
    }
  }

}

.Trainings--overlay {
  @include fixed-cover;

  display: none;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  &.Show {
    display: block;
  }
}

