@import '../scss/_variables.scss';

.lightMode {
  .Footer {
    background: linear-gradient($bg-gradient-light-2, $bg-gradient-light-3);
    color: $font-color-layout-light;
  }
}

.darkMode {
  .Footer {
    background: linear-gradient($bg-gradient-dark-2, $bg-gradient-dark-3);
    color: $font-color-layout-dark;
  }
}

.Footer {
  height: 300px;
  padding: $padding-mobile $padding-mobile;
  @media screen and (min-width: $screen-size-desktop) {
    height: 450px;
    min-height: 30vh;
    padding: 45px 30px;
  }
  .Footer--wrapper{
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content:  space-between;
    .Footer-item {
      cursor: pointer;
      display: flex;
      font-size: $font-body-standard;
      @media screen and (min-width: $screen-size-desktop) {
        font-size: $font-body-standard;
      }
    }
    .Footer-item--center {
      pointer-events: none;
      font-family: 'Jost', sans-serif;
      font-size: $font-header-small;
      // font-weight: 600;
      text-align: center;
      @media screen and (min-width: $screen-size-desktop) {
        font-size: 4rem;
      }
    }
    .Footer-item--top, .Footer-item--bottom  {
      width: 100%;
      font-family: 'Noto Sans', sans-serif;
      text-transform: uppercase;
      letter-spacing: $font-letter-spacing-standard;
      justify-content: space-between;
    }
    .Footer-item--top  {
      justify-content: space-between;
      .push {
        cursor: pointer;
        display: none;
        @media screen and (min-width: $screen-size-desktop) {
          display: inline;
        }
      }
    }
    .Footer-item--bottom  {
      justify-content: space-between;
      .push {
        margin-left: auto;
      }
    }
  }
}