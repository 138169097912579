@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;1,100;1,200;1,300;1,400&display=swap');
@import url('./scss/_reset.scss');

html {
  font-size: calc(100vw / 414 * 10);
  @media screen and (min-width: 992px) {
    font-size: calc(100vw / 1680 * 10);
  }
}

body {
  // cursor: pointer !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*, body {
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

#circle {
  background: radial-gradient(circle at 50% 100%, #7885a8 10%, rgba(101, 110, 135, 0) 70%) !important;
}

.fixed-position {
  position: fixed;
}

 a {
  color: inherit;
  text-decoration: inherit; /* no underline */
  cursor: pointer;
  outline:none !important;
 }

 // button reset style
 button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
 }

 // show credits as overlay on individual images
.credits-overlay {
  display: block;
  font-family: 'Noto Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 9px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.credits {
  display: none;
  font-family: 'Noto Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 9px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

//* Only necessary with horizontal scrolling */
// html[data-scroll-orientation="horizontal"] {
//   body {
//       width: fit-content;
//   }
//   [data-scroll-container] {
//       display: flex;
//   }
// }


//locomotive-scroll 5 basic styles 
// Vendors
@import 'node_modules/locomotive-scroll/dist/locomotive-scroll';

html {
  overflow-x: hidden;
}

/* Only necessary with horizontal scrolling */
html[data-scroll-orientation="horizontal"] {
  body {
      width: fit-content;
  }
  [data-scroll-container] {
      display: flex;
  }
}


