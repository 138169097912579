$lightgrey-color: #D6CFCF;
$darkblue-color: #3E5871;
$font-lightgrey-color: #C2C2C2;


// Variables for gradient bg
$bg-gradient-dark-1: #1d1d1d;
$bg-gradient-dark-2: #505876;
$bg-gradient-dark-3: #5f698f;
$bg-gradient-dark-4: #6b799e;
$bg-gradient-dark-5: #7885a8;

$bg-gradient-light-1: #ced4de;
$bg-gradient-light-2: #b2becd;
$bg-gradient-light-3: #9babbf;
$bg-gradient-light-4: #8593b0;
$bg-gradient-light-5: #7885a8;

// Font Color 
$font-color-layout-dark: #D6CFCF;
$font-color-layout-light: #577189;

$font-header-extra-big: 8rem;
$font-header-big: 6rem;
$font-header-medium: 4.2rem;
$font-header-small: 3.2rem;
$font-quote: 2.8rem;
$font-body-standard-ex: 1.8rem;
$font-body-standard: 1.6rem;
$font-body-workshop: 1.4rem;
$font-body-small-mobile: 1.4rem;

$font-body-small-detail: 1.2rem;

$font-letter-spacing-standard: 4px;
$font-line-height-standard: 1.5;

$padding-mobile: 25px;
$padding-desktop-s: 50px;
$padding-desktop-m: 100px;
$padding-desktop-l: 125px;


$section-space-mobile: 10vh;
$section-space-desktop-s: 10vw;
$section-space-desktop: 20vh;

$screen-size-small: 769px;
$screen-size-desktop: 992px;
$screen-size-desktop-m: 1024px; //height 600-768
$screen-size-desktop-l: 1280px;
$screen-size-desktop-xl: 1920px;



