.Logo--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Logo {
  display: inline-block;
  text-transform: uppercase;
  >p{
    margin: 0;
    letter-spacing: 8px;
    text-align: center;
  }
  >p:nth-of-type(1) {
    font-size: 26px;
    font-weight: 500;
    @media screen and (min-width: 992px) {
      font-size: 38px;
    }
  }
  >p:nth-of-type(2) {
    font-size: 8px;
    font-weight: 300;
    padding-top: 5px;
    @media screen and (min-width: 992px) {
      font-size: 15px;
      // padding-top: 0px;
    }
  }
}