@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

.lightMode {
  .Performances {
    color: $font-color-layout-light;
    background: $bg-gradient-light-1;
    .Performances--navigation {
      border: 1px solid $font-color-layout-light;
    }
  }
}

.darkMode {
  .Performances {
    color: $bg-gradient-light-1;
    background: $bg-gradient-dark-1;
    .Performances--navigation {
      border: 1px solid $bg-gradient-light-1;
    }
  }
}


.Performances {
  @include flex-column-center;

  width: 100vw;
  height: 100%;
  padding: 75px 0px;
  p {
    @include font-small-header-standard;
    font-size: $font-body-small-mobile;

    width: calc(100vw - 2* $padding-mobile);
    line-height: $font-line-height-standard;
    text-align: center;
    @media screen and (min-width: $screen-size-desktop) {
      width: 75vw;
      font-size: $font-body-standard;
      text-align: left;
    }
    &:nth-of-type(2) {
      text-align: center;
      @media screen and (min-width: $screen-size-desktop) {
        text-align: right;
      }
    }
  }
  .Performances--imageControl {
    display: flex;
    flex-direction: row;
    margin: 30px 15px 0px 15px;
    gap: 20px;
    @media screen and (min-width: $screen-size-desktop) {
      margin: 30px 15px;
      gap: 30px;
    }
    > div:not(.active) {
      filter: grayscale(100%);
    }
    > div > div{
      cursor: pointer;
      width: 45px;
      height: 45px;
      background-size: cover;
      border-radius: 50%;
      @media screen and (min-width: $screen-size-desktop) {
        width: 60px;
        height: 60px;
      }
    }
  }
  .Performances--mediaWrapper {
    display: flex;
    justify-content: center;
    width: calc(100vw - 2* $padding-mobile);
    margin-top: $padding-mobile;
    margin-bottom: $padding-mobile;
    filter: grayscale(100%);
    @media screen and (min-width: $screen-size-desktop) {
      width: 75vw;
    }
    > div:nth-of-type(1) {
      flex-grow: 1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: -1px;
    }
    .Performances--mediaWrapper--video {
      position: relative;
      width: calc(100vw - 2* $padding-mobile);
      height: calc(((100vw - 2* $padding-mobile)/5)*4);
      overflow: hidden;
      //5:4
      @media screen and (min-width: $screen-size-desktop) {
        width: 75vh;
        height: 60vh;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100vw - 2* $padding-mobile);
        height: calc(((100vw - 2* $padding-mobile)/5)*4);
        &.visible {
          display: block;
        }
        &.hidden {
          display: none;
        }
        @media screen and (min-width: $screen-size-desktop) {
          width: 75vh;
          height: 60vh;
        }
      }
    }
  }
}

