@import '../scss/_variables.scss';
@import '../scss/_mixins.scss';

// Color setting for dark/light Mode
.lightMode {
  .Landing {
    background: linear-gradient($bg-gradient-light-1, $bg-gradient-light-2);
  }
}

.darkMode {
  .Landing {
    background: linear-gradient($bg-gradient-dark-1, $bg-gradient-dark-2);
  }
}


.Landing {
  @include flex-cover;
  align-items: center;

  .Landing--imgWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: $screen-size-desktop) {
      width: 80%;
      height: 60%;
    }
    > div {
      @include background-cover;

      display: inline-block;
      height: 100%;
    }
    > div:nth-of-type(1) {
      width: 100%;
    }
    > div:nth-of-type(2) {
      display: none;
    }
    @media screen and (min-width: $screen-size-desktop) {
      > div:nth-of-type(1) {
        width: 30%;
      }
      > div:nth-of-type(2) {
        display: inline-block;
        width: 70%;
      }
    }
  }
}